import Link from "next/link";
import React from "react";
import { CardWrapper, StyledText, IconWrapper } from "./IconTextCard.styles";
import { RowDiv } from "@/outscal-commons-frontend/Styled/LayoutUtils";
import { UIElements } from "@/outscal-commons-frontend/Hooks/useAnalyticsClickEvent";
import DynamicIcon from "@/mvcComponents/Commons/DynamicIcons/DynamicIcons.controller";

const IconTextCardView = ({
  item,
  onClick,
  cursor,
  analyticsText,
  customCardData,
}) => {
  const {
    text = "",
    link = "",
    // icon: Icon,
    icon,
  } = item || {};

  const {
    primaryColor = "themeBlue",
    secondaryColor = "lightBlue",
    highlightColor = "themeYellow",
    borderRadius = "xs",
    padding,
    variant,
    iconSize = "5xl",
  } = customCardData || {};

  const TitleRow = link ? (
    <Link href={link}>
      <StyledText primaryColor={primaryColor}>{text}</StyledText>
    </Link>
  ) : (
    <StyledText primaryColor={primaryColor}>{text}</StyledText>
  );

  return (
    <CardWrapper
      primaryColor={primaryColor}
      secondaryColor={secondaryColor}
      highlightColor={highlightColor}
      borderRadius={borderRadius}
      padding={padding}
      isOutlined={variant == "outlined"}
      cursor={cursor}
      onClick={(e) => onClick(e, link)}
      data-analytics={[
        UIElements.LINK,
        `icon_button${analyticsText ? `_${analyticsText}_${text}` : ""}`,
      ]}
    >
      <RowDiv gap="12px" alignItems="center">
        {icon && (
          <IconWrapper primaryColor={primaryColor} iconSize={iconSize}>
            <DynamicIcon icon={icon} />
          </IconWrapper>
        )}
        {TitleRow}
      </RowDiv>
    </CardWrapper>
  );
};

export default IconTextCardView;
