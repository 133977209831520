import React from "react";
import UseComponentVisibility from "@/hooks/useComponentVisibility";
import GridLayout from "../../../Commons/GridLayout/GridLayout.controller";
import IconTextCard from "../../Cards/IconTextCard/IconTextCard.controller";

const IconTextCardGrid = ({
  heading = "",
  subHeading = "",
  listData = [],
  col = 4,
  minColCount = 2,
  gridGap = 16,
  headingAlignment = "center",
  showOn,
}) => {
  return (
    <UseComponentVisibility conditions={showOn}>
      <section data-outscal-section className="grid-a">
        <GridLayout
          col={col}
          minColCount={minColCount}
          gridGap={gridGap}
          heading={heading}
          subHeading={subHeading}
          headingAlignment={headingAlignment}
          cardComponent={IconTextCard}
          listData={listData}
          //   action={action}
          // customCardData={customCardData}
        />
      </section>
    </UseComponentVisibility>
  );
};

export default IconTextCardGrid;
