import { FaCode, FaUnity, FaCubes, FaDatabase, FaBriefcase, FaJava } from "react-icons/fa";
import { RiGameFill, RiRobot2Fill } from "react-icons/ri";
import { BsFillBadgeVrFill, BsCheck2Circle, BsPersonVcard } from "react-icons/bs";
import { SiUnrealengine, SiApplearcade, SiCodeproject, SiCplusplus, SiCoursera, SiPython, SiHtml5, SiJavascript, SiCsharp } from "react-icons/si";
import { GrTechnology } from "react-icons/gr";
import { GiGamepad, GiTeacher } from "react-icons/gi";
import { MdDesignServices, MdOutlineAssignmentTurnedIn, MdOutlineQuestionMark, MdOutlineQuestionAnswer } from "react-icons/md";
import { AiOutlineClockCircle } from "react-icons/ai";
import { GoCode } from "react-icons/go";
import { DiScala } from "react-icons/di";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";

export const iconsList = {
    FaCode,
    RiGameFill,
    RiRobot2Fill,
    BsFillBadgeVrFill,
    FaUnity,
    SiUnrealengine,
    GrTechnology,
    SiApplearcade,
    GiGamepad,
    MdDesignServices,
    FaCubes,
    FaDatabase,
    BsCheck2Circle,
    MdOutlineAssignmentTurnedIn,
    AiOutlineClockCircle,
    FaBriefcase,
    MdOutlineQuestionMark,
    GoCode,
    SiCodeproject,
    HiOutlineArrowNarrowRight,
    GiTeacher,
    BsPersonVcard,
    MdOutlineQuestionAnswer,
    SiCplusplus,
    SiCoursera,
    SiPython,
    SiHtml5,
    SiJavascript,
    SiCsharp,
    FaJava,
    DiScala
};
