import styled from "styled-components";

export const StyledText = styled.h3`
  flex: 0.8;
  font-size: ${(p) => p.theme.fontSize.md};
  font-weight: ${(p) => p.theme.fontWeight.regular};
  color: ${(p) => p.theme.colors[p.primaryColor]};
  @media screen and (max-width: 500px) {
    font-size: ${(p) => p.theme.fontSize.sm};
  }
`;

export const IconWrapper = styled.div`
  display: flex;
  font-size: ${(p) => p.theme.fontSize[p.iconSize]};
  color: ${(p) => p.theme.colors[p.primaryColor]};
`;

export const CardWrapper = styled.div`
  cursor: ${(p) => p.cursor};
  @media screen and (max-width: 500px) {
    padding: 12px;
  }
  background-color: ${(p) => p.theme.colors[p.secondaryColor]};
  border: 1.5px solid
    ${(p) => p.theme.colors[p.isOutlined ? p.primaryColor : p.secondaryColor]};
  color: ${(p) => p.theme.colors[p.primaryColor]};
  padding: ${(p) => p.padding || "16px"};
  border-radius: ${(p) => p.theme.borderRadius[p.borderRadius || "xs"]};
  ${(p) => p.isOpen && `box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;`}
  transition: all 0.3s ease-in-out;
  &:hover {
    transform: scale(1.05);
    box-shadow: 0 14px 40px rgba(30, 10, 58, 0.1);
    background-color: ${(p) =>
      p.theme.colors[p.isOutlined ? p.secondaryColor : p.primaryColor]};
    ${StyledText} {
      color: ${(p) =>
        p.theme.colors[p.isOutlined ? p.primaryColor : p.secondaryColor]};
    }
    ${IconWrapper} {
      color: ${(p) => p.theme.colors[p.highlightColor]};
    }
  }
`;
