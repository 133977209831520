import React from "react";
import { useRouter } from "next/router";
import IconTextCardView from "./IconTextCard.view";

const IconTextCard = ({ item, customCardData, name }) => {
  const router = useRouter();
  const {
    disableLinkClick = false,
    target = "",
    primaryColor = "themeBlue",
    secondaryColor = "lightBlue",
    highlightColor = "themeYellow",
    borderRadius = "xs",
    padding,
    variant,
    iconSize = "5xl",
  } = customCardData || {};

  const disableClick = (e) => {
    e.preventDefault();
  };

  const onClick = (e, link) => {
    e.preventDefault();
    target == "" ? router.push(link) : window.open(link);
  };

  return (
    <IconTextCardView
      item={item}
      customCardData={customCardData}
      cursor={disableLinkClick ? "initial" : item?.link ? "pointer" : "initial"}
      onClick={
        disableLinkClick ? disableClick : item?.link ? onClick : disableClick
      }
      analyticsText={name}
    />
  );
};

export default IconTextCard;
